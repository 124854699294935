@import url("https://fonts.googleapis.com/css?family=Bangers|Cinzel:400,700,900|Lato:100,300,400,700,900|Lobster|Lora:400,700|Mansalva|Muli:200,300,400,600,700,800,900|Open+Sans:300,400,600,700,800|Oswald:200,300,400,500,600,700|Roboto:100,300,400,500,700,900&display=swap");
@import url('https://fonts.googleapis.com/css?family=Big Shoulders Display');
/* Used Google Fonts */

.App {
  text-align: center;
  min-width: 100vh;
  height: 100vh;
  color: #000;
  min-height: 800px;
  /* max-width: 1240px; */
}
.initials {
  font-size: 50px;
  font-weight: 600;
  color: #fff;
  background-color: #000;
  width: fit-content;
  padding: 10px 30px;
  border-radius: 100%;
}
.initialsB {
  font-size: 50px;
  font-weight: 600;
  background-color: rgb(35 121 218);
  width: fit-content;
  padding: 10px 30px;
  border-radius: 100%;
}
.initialsS {
  font-size: 50px;
  font-weight: 600;
  background-color: rgb(159 87 231);
  width: fit-content;
  padding: 10px 30px;
  border-radius: 100%;
}
.cursor-style {
  background-color: rgba(0, 255, 255, 0.7);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  z-index: 99;
  transform-origin: 100% 100%;
  transform: translate(-50%, -50%);
  position: fixed;
  pointer-events: none;
}
.cursor-style-outer {
  margin-left: -3px;
  margin-top: -3px;
  width: 6px;
  height: 6px;
  position: fixed;
  left: 0;
  top: 0;
  border-radius: 100%;
  pointer-events: none;
  background-color: #66d9ed;
}

.posRel {
  position: relative;
}
.posAbs {
  position: absolute;
  width: 100%;
}
.posRelHead {
  position: relative;
  padding-block: 30px;
}
.posFix {
  position: fixed;
  background-color: rgba(26, 26, 26, 0.8);
  opacity: 1;
  backdrop-filter: saturate(180%) blur(20px);
  transition: 0.5s;
  padding-block: 20px;
  z-index: 99;
  color: #66d9ed;
}

.bgImgCode {
  height: auto;
  max-width: 100%;
  border: none;
  vertical-align: middle;
  display: inline-block;
  width: 582px;
  border-radius: 0;
  box-shadow: none;
}
.HeaderTags {
  display: flex;
  width: 100%;
  justify-content: center;
  font-weight: 700;
  z-index: 99;
}

.headTags {
  padding-inline: 20px;
  cursor: pointer;
  transition: color 0.5s ease-out 0s;
  font-size: 14px;
}
.logo-holder {
  text-align: center;
  cursor: default;
}
.logo-6 h3 {
  color: #66d8ec;
  font-family: "Cinzel", serif;
  font-weight: 300;
  font-size: 23px;
  line-height: 1.3;
}
.logo-6 h3 span {
  background: #2923384d;
  color: #fff;
  display: inline-block;
  line-height: 1.8;
  padding: 0 16px;
}
.chonky {
  z-index: 0;
  position: relative;
  color: black;
}
.chonky::after {
  transition: all 0.1s ease-in-out;
  content: "";
  position: absolute;
  bottom: 13.5%;
  z-index: -1;
  height: 0.3em;
  width: 104%;
  left: -1%;
  background: linear-gradient(
    65deg,
    #2c49d8 0%,
    #2c49d8 100%,
    rgba(255, 209, 0, 0) 100%
  )
}
.chonky3 {
  z-index: 0;
    font-family: "Roboto Mono", monospace;
  position: relative;
  color: black;
  font-size: 26px;
}
.chonky3::after {
  transition: all 0.1s ease-in-out;
  content: "";
  position: absolute;
  bottom: 7.5%;
  z-index: -1;
  height: 0.3em;
  width: 104%;
  left: -1%;
  background:linear-gradient(
    65deg,
    #2c49d8 0%,
    #2c49d8 100%,
    rgba(255, 209, 0, 0) 100%
  )
}
.chonky1 {
  z-index: 0;
  position: relative;
  color: black;
}
.chonky1::after {
  transition: all 0.1s ease-in-out;
  content: "";
  position: absolute;
  bottom: 13.5%;
  z-index: -1;
  height: 0.3em;
  width: 104%;
  left: -1%;
  background: linear-gradient(
    65deg,
    #2c49d8 0%,
    #2c49d8 100%,
    rgba(255, 209, 0, 0) 100%
  );
}
.chonky2 {
  z-index: 0;
  position: relative;
  color: black;
}
.chonky2::after {
  transition: all 0.1s ease-in-out;
  content: "";
  position: absolute;
  bottom: 13.5%;
  z-index: -1;
  height: 0.3em;
  width: 104%;
  left: -1%;
  background: linear-gradient(
    65deg,
    #2c49d8 0%,
    #2c49d8 100%,
    rgba(255, 209, 0, 0) 100%
  )
}
.flexCenter {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
  height: 100vh;
}
.homeTitle {
  font-size: 127px;
  font-family: 'Big Shoulders Display';
  text-transform: uppercase;
  flex-wrap: nowrap;
  width: max-content;
}
.titleDes {
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  text-wrap: nowrap;
  font-family: sans-serif;
}
.workDes {
  max-width: 800px;
  margin-inline: auto;
}
.expertTitle {
  font-family: 'Big Shoulders Display';
  font-size: 75px;
  font-weight: 600;
  line-height: 1.4em;
}
.expertDes {
  font-family: "Poppins", Sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.2em;
  text-align: left;
}
.descrip {
  color: #000;
  font-family: monospace;
  margin-left: 7%;
  border-left: 1px solid #b9b9b9;
  padding-left: 25px;
}
.iconsExp {
  width: 50px !important;
  height: 50px !important;
}
.iconsExp2 {
  width: 42px !important;
  height: 50px !important;
}
/* .goers{
  transition: opacity 0.5s,left 0.5s,top 0.5s, transform 0.5s, right 0.5s, bottom 0.5s;
} */
.ckImg {
  width: inherit;
  min-height: 300px;
  height: 500px;
  object-fit: cover;
  transition: transform 0.2s; /* Animation */
  cursor: pointer;
}
.ckImg:hover {
  transform: scale(1.1);
}
.proDes {
  color: #2f2f2f;
  font-weight: 600;
}
.logoHead {
  position: absolute;
  left: 1%;
  top: -2%;
}
.max124 {
  max-width: 808px !important;
  margin-inline: auto;
  padding-bottom: 20px;
}
.profTitle {
  max-width: 710px;
  margin-block-end: 21px;
  margin-inline: auto;
  font-family: 'Big Shoulders Display';
  font-size: 62px;
  font-weight: 600;
  line-height: 1.1em;
}
.accorTitle {
  font-size: 1.05em;
  line-height: 1.3;
  letter-spacing: 0;
  margin-block: 8px;
  color: #000;
}
.accordDet {
  background-color: #00c45499;
  color: #000;
}
.descExp {
  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.logoWall {
  max-width: 100px;
}
.selfCenter {
  align-self: center;
}
.techStack {
  display: flex;
      align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.tech {
  padding: 5px 15px;
  border-radius: 20px;
  background-color: #00c45499;
  font-size: 14px;
  margin-bottom: 6px;
  font-weight: 600;
}
.testi {
  text-align: left;
  color: #000;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4em;
}
.proThumb {
  width: 85px;
}
.sans {
  font-family: sans-serif;
}
.refLinks {
  color: #000;
  font-family: "Roboto Mono", monospace;
  font-size: 20px;
  margin-block: 10px;
  text-decoration: none;
}
.refLinks:hover {
  color: #66d9ed;
  transition: color 0.5s;
}
.refLinks2 {
  color: #fff;
  font-family: "Roboto Mono", monospace;
  font-size: 20px;
  margin-block: 10px;
  text-decoration: none;
}
.refLinks2:hover {
  color: #66d9ed;
  transition: color 0.5s;
}
.max1240 {
  max-width: 1240px;
  margin-inline: auto;
  text-align: left;
  padding-top: 50px;
  padding-inline: 20px;
}
.VideoTag{
  height: 645px;
}
.flexCenterDed {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sampSS {
  width: -webkit-fill-available;
  margin-top: 38px;
}
.wfit {
  width: -webkit-fill-available;
  margin-top: 10px;
}
.proTitle {
  font-size: 50px;
}
.linkButton{
  margin-block-start: 20px;
      width: fit-content;
    background-color: #e0e0e0;
    padding: 16px;
    border-radius: 40px;
}
@media screen and (max-width: 1660px) {
 .ckImg{
  height: 300px;
  object-fit: cover;
 }
}
@media screen and (max-width: 768px) {
  .App {
    min-width: unset;
  }
  .homeTitle {
  font-size: 60px;
  letter-spacing: 8.2px;
}
.mobile_imgs{
  width: 100vw !important;
}
.VideoTag{
  height: 600px;
}
  .headTags {
    padding-inline: 20px;
    padding-block: 7px;
    font-size: 17px;
    line-height: 2em;
  }
  .accorTitle {
    font-size: 14px;
  }
  .tech {
    padding: 5px 4px;
    margin-inline-end: 5px;
    font-size: 11px;
        white-space: nowrap;
  }
  .logoWall {
    width: -webkit-fill-available;
    margin-block-end: 42px;
  }
  .descExp {
    font-size: 12px;
  }
  .expertDes {
    font-size: 18px;
  }
  .profTitle {
    font-size: 43px;
  }
  .expertTitle {
    font-size: 58px;
  }
  .titleDes {
    font-size: 15px;
    letter-spacing: 0px;
  }
  .css-mmfkas-MuiGrid-root {
    margin-bottom: 10px !important;
  }
  .chonky3 {
    font-size: 20px;
  }
  .proTitle {
    font-size: 25px;
  }
  .workDes {
    padding-inline: 10px;
  }
}
@media screen and (max-width: 425px) {
  .ckImg {
    height: 154px;
  }
  .tech{
    font-size: 8px;
  }
}
@media screen and (max-width: 320px) {
  .VideoTag{
  height: 510px;
}
.homeTitle{
      font-size: 53px;
}
.titleDes{
  font-size: 13px;
}
  .accorTitle {
    font-size: 11px;
  }
  .proTitle {
    font-size: 23px;
  }
}
.drawerSize {
  width: calc(100% - 56px);
}
body.loading {
  overflow: hidden;
  height: 100vh;
  background-color: #fff;
}

.loader {
  .loader-inner {
    .image-block {
      position: absolute;
      transform-origin: center;
      display: flex;
      align-items: center;
      justify-content: center;
      &.image-1 {
        width: 400px;
        left: 3%;
        bottom: 5%;
        @media (max-width: 960px) {
          width: 287px;
          left: 0%;
          bottom: 10%;
        }
      }
      &.image-3 {
        width: 300px;
        right: 12%;
        top: 5%;
        @media (max-width: 960px) {
          width: 200px;
          top: 12%;
          right: 1%;
        }
      }
      &.image-4 {
        max-width: 400px;
        width: 53%;
        right: 8%;
        bottom: 10%;
        @media (max-width: 960px) {
          max-width: 287px;
          right: 2%;
          bottom: 22%;
        }
      }
      &.image-5 {
        width: 370px;
        left: 9%;
        top: 5%;
        @media (max-width: 960px) {
          width: 250px;
          left: 2%;
          top: 9%;
        }
      }
      img {
        width: 100%;
        object-fit: fill;
      }
    }
  }
}
.transition-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: center;
  img {
    width: 800px;
    display: flex;
  }
  &.final {
    display: block;
    top: -128px;
    position: relative;
    width: 90%;
    margin: 0 auto;
    z-index: -100;
    @media (max-width: 960px) {
      top: -56px;
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }
}